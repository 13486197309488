import React from "react";
import { Input, Form, InputProps } from "antd";

import styles from "./input.module.scss";

interface CustomInputProps extends InputProps {
  className?: string;
  name: string;
}

const CustomInput: React.FC<CustomInputProps> = ({
  className,
  name,
  ...inputProps
}) => {
  return (
    <Form.Item name={name} noStyle>
      <Input
        className={`${styles["input-message"]} ${className}`}
        {...inputProps}
      />
    </Form.Item>
  );
};

export default CustomInput;
