import { Avatar } from "antd";
import { ReactElement } from "react";

import styles from "./avatar.module.scss";

interface IProps {
  className?: string;
  size?: number;
  gap?: number;
  name: string | ReactElement;
  nameClassName?: string;
  color?: string;
  profilePic?: string;
}

const CustomAvatar = ({
  className,
  size,
  gap,
  name,
  color,
  profilePic,
  nameClassName,
}: IProps) => {
  return (
    <Avatar
      size={size ?? 30}
      gap={gap ?? 4}
      src={profilePic}
      style={{ backgroundColor: !profilePic ? color : undefined }}
      className={`${styles["custom-avatar"]} ${className}`}
    >
      {!profilePic && (
        <p className={`${styles["name"]} ${nameClassName}`}>{name}</p>
      )}
    </Avatar>
  );
};
export default CustomAvatar;
