import React, { useState, useEffect, useRef } from "react";
import { Button, Form, message } from "antd";
import Markdown from "markdown-to-jsx";

import { ReactComponent as SendIcon } from "../assets/images/sendicon.svg";
import { ReactComponent as CrossIcon } from "../assets/images/crossicon.svg";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { getApiErrorMsg } from "../utils/object";
import CustomAvatar from "../components/avatar";
import CustomInput from "../components/input";
import CustomButton from "../components/button";
import api from "./../api/api";

import styles from "./chatbot.module.scss";

interface ChatbotProps {
  logoUrl?: string;
  popupClassName?: string;
  buttontext?: string;
  chatbotName?: string;
  headingClassName?: string;
}
interface Message {
  text: string | JSX.Element;
  type: "question" | "answer";
}

const Chatbot: React.FC<ChatbotProps> = ({
  logoUrl,
  popupClassName,
  buttontext,
  chatbotName,
  headingClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [name, setName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const authToken = localStorage.getItem("user-access-token");

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  const formatResponse = (text: any) => {
    return <Markdown>{text}</Markdown>;
  };

  const handleCancel = () => {
    setMessages([]);
  };

  useEffect(() => {
    if (authToken) {
      api
        .get("/suggestion_queries/")
        .then((response) => {
          setSuggestions(response.data.query_suggestions);
        })
        .catch((error) => {
          message.error({
            content: getApiErrorMsg(error),
            key: "fetch-error",
            duration: 2,
          });
        });
      api
        .get("/user-details/")
        .then((response) => {
          setName(response.data.employee_name);
        })
        .catch((error) => {
          message.error({
            content: getApiErrorMsg(error),
            key: "fetch-error",
            duration: 2,
          });
        });
    }
  }, []);

  const getInitials = (value: string) => {
    let initial = value?.[0]?.toUpperCase();
    const firstNameArr = value?.split(" ");
    const lastName = firstNameArr?.[1];
    if (lastName) {
      initial += lastName[0]?.toUpperCase();
    }
    return initial;
  };

  const handleSendMessage = (values: { message: string }) => {
    if (!loading) {
      const inputValue = values.message.trim();
      if (inputValue !== "") {
        const newQuestion: Message = {
          text: inputValue,
          type: "question",
        };
        setMessages([...messages, newQuestion]);
        setLoading(true);
        form.setFieldValue("message", "");
        api
          .post("/query/", { query: inputValue })
          .then((response) => {
            const formattedAnswer = response?.data?.answer
              ? formatResponse(response.data.answer)
              : "";
            const newAnswer: Message = {
              text: formattedAnswer,
              type: "answer",
            };
            setMessages((prevMessages) => [...prevMessages, newAnswer]);
          })
          .catch((error) => {
            message.error({
              content: getApiErrorMsg(error),
              key: "fetch-error",
              duration: 2,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  if (!authToken) {
    return null;
  }

  return (
    <div className={styles["chatbot-container"]}>
      <button className={styles["toggle-button"]} onClick={toggleChatbot}>
        {isOpen ? (
          <CrossIcon onClick={handleCancel} />
        ) : (
          <>
            {logoUrl ? (
              <img src={logoUrl} alt="logo" />
            ) : (
              <Logo width="25px" height="15px" />
            )}
            <h2 className={styles["button-text"]}>{buttontext}</h2>
          </>
        )}
      </button>
      <div
        className={`${styles["chatbot-popup"]} ${
          isOpen ? styles["open"] : ""
        } ${popupClassName}`}
      >
        {messages.length > 0 ? (
          <h1 className={styles["title"]}>{chatbotName}</h1>
        ) : (
          <div className={styles["header"]}>
            <h1 className={`${styles["heading"]} ${headingClassName}`}>
              {chatbotName}
            </h1>
            <p className={styles["subheading"]}>
              Choose a prompt below or write your own to start chatting
            </p>
          </div>
        )}
        <div className={styles["chat-container"]}>
          <div className={styles["message-list"]} id={"message"}>
            {messages.map((message, index: number) => (
              <div
                key={index + 1}
                className={`${styles["message"]} ${
                  message.type === "question" ? styles["right"] : styles["left"]
                }`}
              >
                <p className={styles["message-text"]}>{message.text}</p>
                <div>
                  {message.type !== "question" ? (
                    <CustomAvatar
                      className={styles["custom-ai-logo"]}
                      name={<Logo />}
                    />
                  ) : (
                    <CustomAvatar
                      className={styles["custom-avatar"]}
                      name={getInitials(name)}
                    />
                  )}
                </div>
              </div>
            ))}
            {loading && (
              <div className={styles["loading-dots"]}>
                <span className={styles["dot"]} />
                <span className={styles["dot"]} />
                <span className={styles["dot"]} />
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div>
          {messages.length === 0 && (
            <div className={styles["suggestions-wrapper"]}>
              {suggestions.map((suggestion, index) => (
                <CustomButton
                  key={index + 1}
                  text={suggestion}
                  className={styles["suggestions"]}
                  form={form}
                />
              ))}
            </div>
          )}
          <div className={styles["input-wrapper"]}>
            <div className={styles["input-container"]}>
              <Form
                form={form}
                onFinish={handleSendMessage}
                className={styles["custom-form"]}
              >
                <CustomInput
                  placeholder="Ask our chatbot anything..."
                  name="message"
                />
              </Form>
              <Button
                type="text"
                onClick={form.submit}
                className={styles["send-button"]}
                disabled={loading}
              >
                <SendIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
