import { FormInstance } from "antd";

import styles from "./button.module.scss";

interface IProps {
  text: string;
  className?: string;
  form: FormInstance;
}

const CustomButton = ({ text, className, form }: IProps) => {
  const handleSuggestion = (val: string) => {
    form.setFieldsValue({ message: val });
  };
  return (
    <button
      className={`${styles["suggestions"]} ${className}`}
      onClick={() => handleSuggestion(text)}
    >
      {text}
    </button>
  );
};
export default CustomButton;
