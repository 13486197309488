import { message } from "antd";
import axios from "axios";

const paramsSerializer = (params: { [key: string]: string }) =>
  Object.entries(params)
    .map(([key, value]) => key + "=" + (value || ""))
    .join("&") || "";
const api = axios.create({ paramsSerializer });
api.interceptors.request.use(
  (config) => {
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Headers"] =
      "Origin, X-Requested-With, Content-Type, Accept";
    config.baseURL = process.env.REACT_APP_API_URL;

    const token = localStorage.getItem("user-access-token");
    // const token = localStorage.getItem("chatAppAccessToken");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => Promise.reject(error)
);
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      // window.location.pathname = "/";
      message.error({
        content: "User unauthorized!",
        key: "unauthorized",
        duration: 2,
      });
    }
    throw error;
  }
);
export default api;
